import { useEffect } from "react";
import { Form, Input, message } from "antd";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import BaseLayout from "./components/BaseLayout";
import Sidenav from "./components/Sidenav";
import Apis from "apis/remotes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const ConfigureSetting = (): JSX.Element => {
  const [dayCountForm] = Form.useForm();
  const [pointTimeForm] = Form.useForm();

  const [discountPriceForm] = Form.useForm();
  const [hourPriceForm] = Form.useForm();
  const [premiumPriceForm] = Form.useForm();
  const [standardPriceForm] = Form.useForm();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await Apis.getConfig();
        const config = response.data;

        dayCountForm.setFieldsValue({ dayCount: config.dayCount.value });
        pointTimeForm.setFieldsValue({ pointTime: config.pointTime.value });

        discountPriceForm.setFieldsValue({ discountPrice: config.discountPrice.value });
        hourPriceForm.setFieldsValue({ hourPrice: config.hourPrice.value });
        premiumPriceForm.setFieldsValue({ premiumPrice: config.premiumPrice.value });
        standardPriceForm.setFieldsValue({ standardPrice: config.standardPrice.value });
      } catch (error) {
        console.error("Error fetching configuration:", error);
      }
    };

    fetchConfig();
  }, [
    dayCountForm,
    pointTimeForm,
    discountPriceForm,
    hourPriceForm,
    premiumPriceForm,
    standardPriceForm,
  ]);

  const handleSubmit = async (key: string, value: string | number) => {
    try {
      await Apis.putConfig({ key, value }).then((resolve) => {
        message.success(`${key}가 ${value}로 업데이트 되었습니다.`);
      });
    } catch (error) {
      console.error("Error updating configuration:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="point_reward">
                    <MDBox m={2}>
                      <Form
                        form={dayCountForm}
                        onFinish={(values) => handleSubmit("dayCount", values.dayCount)}
                      >
                        <Form.Item
                          name="dayCount"
                          label="일일 적립 제한 횟수"
                          rules={[
                            {
                              required: true,
                              message: "일일 적립 제한 횟수를 입력해주세요.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton type="submit" color="info">
                            일일 적립 제한 횟수 수정
                          </MDButton>
                        </Form.Item>
                      </Form>
                    </MDBox>
                  </Card>
                </Grid>

                {/* discountPrice */}
                <Grid item xs={12}>
                  <Card id="daily_savings">
                    <MDBox m={2}>
                      <Form
                        form={discountPriceForm}
                        onFinish={(values) => handleSubmit("discountPrice", values.discountPrice)}
                      >
                        <Form.Item
                          name="discountPrice"
                          label="이벤트 배너 할인 상품 가격"
                          rules={[
                            {
                              required: true,
                              message: "이벤트 배너 할인 상품 가격을 입력해주세요.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton type="submit" color="info">
                            이벤트 배너 할인 상품 가격 수정
                          </MDButton>
                        </Form.Item>
                      </Form>
                    </MDBox>
                  </Card>
                </Grid>

                {/* hourPrice */}
                <Grid item xs={12}>
                  <Card id="daily_savings">
                    <MDBox m={2}>
                      <Form
                        form={hourPriceForm}
                        onFinish={(values) => handleSubmit("hourPrice", values.hourPrice)}
                      >
                        <Form.Item
                          name="hourPrice"
                          label="라이브 배너 상품 가격"
                          rules={[
                            {
                              required: true,
                              message: "라이브 배너 상품 가격을 입력해주세요.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton type="submit" color="info">
                            라이브 배너 상품 가격 수정
                          </MDButton>
                        </Form.Item>
                      </Form>
                    </MDBox>
                  </Card>
                </Grid>

                {/* premiumPrice */}
                <Grid item xs={12}>
                  <Card id="daily_savings">
                    <MDBox m={2}>
                      <Form
                        form={premiumPriceForm}
                        onFinish={(values) => handleSubmit("premiumPrice", values.premiumPrice)}
                      >
                        <Form.Item
                          name="premiumPrice"
                          label="프리미엄 플랜 가격"
                          rules={[
                            {
                              required: true,
                              message: "프리미엄 플랜 가격을 입력해주세요.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton type="submit" color="info">
                            프리미엄 플랜 가격 수정
                          </MDButton>
                        </Form.Item>
                      </Form>
                    </MDBox>
                  </Card>
                </Grid>

                {/* standardPrice */}
                <Grid item xs={12}>
                  <Card id="daily_savings">
                    <MDBox m={2}>
                      <Form
                        form={standardPriceForm}
                        onFinish={(values) => handleSubmit("standardPrice", values.standardPrice)}
                      >
                        <Form.Item
                          name="standardPrice"
                          label="프리미엄 플랜 가격"
                          rules={[
                            {
                              required: true,
                              message: "프리미엄 플랜 가격을 입력해주세요.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton type="submit" color="info">
                            프리미엄 플랜 가격 수정
                          </MDButton>
                        </Form.Item>
                      </Form>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card id="rewards">
                    <MDBox m={2}>
                      <Form
                        form={pointTimeForm}
                        onFinish={(values) => handleSubmit("pointTime", values.pointTime)}
                      >
                        <Form.Item
                          name="pointTime"
                          label="보상 지연 시간(초)"
                          rules={[
                            {
                              required: true,
                              message: "보상 지연 시간을 입력해주세요.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MDButton type="submit" color="info">
                            보상 지연 시간 수정
                          </MDButton>
                        </Form.Item>
                      </Form>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default ConfigureSetting;
