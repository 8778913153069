/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

import Apis from "apis/remotes";
import { IUser } from "apis/response";

import { Pagination, PaginationProps, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsFilter } from "utils/ParamsFilter";

import { tableColumn } from "./components/data";
import Search from "antd/es/input/Search";

function UserList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page") ? Number(params.get("page")) : 1;

  // const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [notices, setNotices] = useState<IUser[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      `/pages/user/user-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };

  const handleSearch = (text: string) => {
    navigate(
      `/pages/user/user-list${paramsFilter({
        sort,
        order,
        search: text,
        page: 1,
      })}`
    );
  };

  /**
   * const {field,order} = sorter;
   * 1. field와  order를 확인
   * 2. field와 order는 undefined일 수 있음
   * 3. field가 undefined 또는 order가 undefined인 경우 정렬을 초기화함
   * 4. field가 있고 order가 있으면 정렬함
   * 5. page & search, sort, order
   */
  const handleSort = (sorter: any) => {
    const { field, order } = sorter;
    console.log(field, order);
    if (field && order) {
      navigate(
        `/pages/user/user-list${paramsFilter({
          sort: field,
          order: order.replace("end", "").toUpperCase(),
          search,
          page,
        })}`
      );
    } else {
      navigate(
        `/pages/user/user-list${paramsFilter({
          search,
          page,
        })}`
      );
    }
  };

  useEffect(() => {
    Apis.getUser({ page: page, perPage: 10, search, sort, order })
      .then((resolve) => {
        setNotices(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, search, sort, order]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <Table
            title={() => (
              <MDBox sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Search
                  defaultValue={search}
                  style={{ maxWidth: 360 }}
                  maxLength={30}
                  placeholder="회원의 이메일 또는 휴대전화번호를 검색하세요"
                  onSearch={(text) => handleSearch(text)}
                />
              </MDBox>
            )}
            pagination={false}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            dataSource={notices}
            columns={tableColumn}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/page/user/detail-user/${record.userId}`);
                },
              };
            }}
            onChange={(val: any, filter: any, sorter: any, extra: any) => {
              handleSort(sorter);
            }}
            footer={() => (
              <Pagination
                current={page}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserList;
