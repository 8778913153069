/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Apis from "apis/remotes";
import { IGift, IGiftInvalid } from "apis/response";
import {
  message,
  Pagination,
  PaginationProps,
  Table,
  Modal,
  Form,
  Input,
  Button,
  Space,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { paramsFilter } from "utils/ParamsFilter";
import MDButton from "components/MDButton";

import { ColumnsType } from "antd/es/table";
import MDTypography from "components/MDTypography";
import FormItem from "antd/es/form/FormItem";

// 모달 내부 테이블의 컬럼 정의
const invalidGiftColumns = [
  {
    title: "상품 ID",
    dataIndex: "giftishowId",
    key: "giftishowId",
  },
  {
    title: "상품명",
    dataIndex: "goodsName",
    key: "goodsName",
  },
  {
    title: "상품 코드",
    dataIndex: "goodsCode",
    key: "goodsCode",
  },
];

function GiftList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page") ? Number(params.get("page")) : 1;

  // const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [gifts, setGifts] = useState<IGift[]>([]);
  const [total, setTotal] = useState(0);
  const [bizMoney, setBizMoney] = useState<number>(0); // 비즈 포인트 잔액

  /** 기프티쇼 상품 유효성 검사 */
  const [loading, setLoading] = useState(false);
  /** 모달 오픈용 */
  const [open, setOpen] = useState(false);
  const [invalidGifts, setInvalidGifts] = useState<IGiftInvalid[]>([]);

  // 비즈 포인트 잔액 가져오기
  const fetchBizMoney = () => {
    Apis.getGiftBizMoney()
      .then((resolve) => {
        setBizMoney(resolve.data.balance); // API의 응답 형식에 따라 수정
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableColumn: ColumnsType<IGift> = [
    { title: "아이디", dataIndex: "giftishowId", key: "giftishowId" },
    {
      title: "이미지",
      dataIndex: "goodsImg",
      key: "goodsImg",
      render: (goodsImg: string) => (
        <img src={goodsImg} alt="상품 이미지" style={{ width: 100, height: 100 }} />
      ),
    },
    {
      title: "상품명",
      dataIndex: "goodsName",
      key: "goodsName",
      ellipsis: true,
    },
    {
      title: "브랜드명",
      dataIndex: "brandName",
      key: "brandName",
      ellipsis: true,
    },
    {
      title: "카테고리",
      dataIndex: "categoryName",
      key: "categoryName",
      ellipsis: true,
    },
    {
      title: "할인가격",
      dataIndex: "discountPrice",
      key: "discountPrice",
      ellipsis: true,
      render: (discountPrice: number) => `${discountPrice.toLocaleString()}원`,
    },
    {
      title: "상품 내리기",
      key: "action",
      render: (_, record) => (
        <MDButton
          variant="outlined"
          color="error"
          onClick={() => handlePutGift(record.giftishowId)}
        >
          내리기
        </MDButton>
      ),
    },
    // 상품 내리기 버튼 하나 추가하고 해당 버튼 클릭하면 아래 함수 수행하는 코드 작성해봐

    //  putGiftById(id: string | number) {
    //   return api.put(`gift/${id}`);
    // }
  ];
  // 상품 내리기 함수
  const handlePutGift = (id: string | number) => {
    Modal.confirm({
      title: "상품을 내리시겠습니까?",
      content: "해당 상품이 비활성화됩니다.",
      okText: "예",
      cancelText: "아니요",
      onOk: () => {
        Apis.putGiftById(id)
          .then(() => {
            Modal.success({
              title: "성공",
              content: "상품이 성공적으로 내려갔습니다.",
            });
            // 상품 목록 다시 로드
            fetchGifts();
          })
          .catch((error) => {
            console.error(error);
            Modal.error({
              title: "오류",
              content: "상품을 내리는 데 실패했습니다.",
            });
          });
      },
    });
  };
  // 상품 목록 가져오는 함수 (useEffect에서 사용)
  const fetchGifts = () => {
    Apis.getGift({ page: page, perPage: 10, search, sort, order })
      .then((resolve) => {
        setGifts(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    // setCurrentPage(page);
    navigate(
      `/pages/gift/gift-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
    // setCurrentPage(page);
  };

  const handleCheckGiftInvalid = () => {
    setLoading(true);
    Apis.getGiftInvalid()
      .then((resolve) => {
        if (resolve.data.data.length > 0) {
          setInvalidGifts(resolve.data.data);
          setOpen(true);
        } else {
          setInvalidGifts([]);
          message.info("문제 없음.");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateNotice = async (noticeSubmitForm: FormData) => {
    setLoading(false);
    Apis.postGift(noticeSubmitForm)
      .then((resolve) => {
        message.success("기프티쇼 상품이 등록되었습니다.");
        navigate("/pages/gift/gift-list?page=1");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGifts();
    fetchBizMoney(); // 비즈 포인트 잔액 가져오기
  }, [page, search, sort, order]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox py={3} display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6">
              비즈 포인트 잔액: {bizMoney.toLocaleString()}원
            </MDTypography>
          </MDBox>

          <MDBox gap={2} display={"flex"}>
            <MDButton
              disabled={loading}
              onClick={handleCheckGiftInvalid}
              variant="gradient"
              color="dark"
            >
              기프티쇼 상품 유효성 검사
            </MDButton>
            {/* <MDButton LinkComponent={Link} to="/page/gift/new-gift" variant="gradient" color="info">
              기프티쇼 상품 추가
            </MDButton> */}
          </MDBox>
        </MDBox>

        <Card>
          <Table
            title={() => (
              <Form
                layout="horizontal"
                style={{
                  maxWidth: 600,
                }}
                onFinish={handleCreateNotice}
              >
                <Space className="flex items-center">
                  <FormItem
                    name="goodsCode"
                    rules={[{ required: true, message: "상품코드입력 필수" }]}
                  >
                    <Input placeholder="상품코드 입력 ex) G00003151420 " />
                  </FormItem>
                  <FormItem>
                    <Button loading={loading} disabled={loading} htmlType="submit">
                      등록
                    </Button>
                  </FormItem>
                </Space>
              </Form>
            )}
            pagination={false}
            rowKey={(record) => record.giftishowId}
            scroll={{ x: "max-content" }}
            dataSource={gifts}
            columns={tableColumn}
            footer={() => (
              <Pagination
                current={page}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
      {/* 모달 */}
      <Modal
        title="유효하지 않은 기프티쇼 상품"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okText="닫기"
        cancelButtonProps={{ style: { display: "none" } }} // 취소 버튼 숨김
      >
        <Table
          columns={invalidGiftColumns}
          dataSource={invalidGifts}
          rowKey="giftishowId"
          pagination={false}
        />
      </Modal>
    </DashboardLayout>
  );
}

export default GiftList;
