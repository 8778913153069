import Apis from "apis/remotes";
import { IGetBannerById } from "apis/response";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MediaInfo from "./components/MediaInfo";
import ThumbnailInfo from "./components/ThumbnailInfo";

import Expiration from "./components/Expiration";
import TargetLink from "./components/TargetLink";
// import Config from "./components/Config";
import { App, UploadFile } from "antd";
import axios from "axios";
import { convertDateToYYYYMMDD } from "utils/utilDate";
import BannerInfo from "./components/BannerInfo";
import { Card, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDButton from "components/MDButton";
// import BannerApproveCard from "./components/BannerApproveCard";

function BannerDetails(): JSX.Element {
  const navigate = useNavigate();
  const { message, notification } = App.useApp();
  const { id } = useParams();
  const [data, setData] = useState<IGetBannerById>();
  const [loading, setLoading] = useState<boolean>(false);

  const putTargetLink = (target: string) => {
    Apis.putBanner(id, { target })
      .then((resolve) => {
        setData((prev) => {
          return { target, ...prev };
        });
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };
  const putVisibleTo = (option: Date | null) => {
    let visibleTo = convertDateToYYYYMMDD(option);

    Apis.putBanner(id, { visibleTo })
      .then((resolve) => {
        setData((prev) => {
          return { visibleTo, ...prev };
        });
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };

  // const putClientID = (client_id: number) => {
  //   Apis.putBanner(id, { client_id })
  //     .then((resolve) => {
  //       setData((prev) => {
  //         return { client_id, ...prev };
  //       });
  //       message.success("Success");
  //     })
  //     .catch((error) => {
  //       notification.error({
  //         message: error.response.data.message,
  //       });
  //     });
  // };

  const putThumbnail = async (mimeType: string, file: UploadFile[]) => {
    setLoading(true);
    try {
      const type = mimeType.split("/")[0];
      const uploadResponse = await Apis.postFilesUpload(type, mimeType);
      const { path, url } = uploadResponse.data;

      await axios.put(url, file[0].originFileObj, {
        headers: {
          "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
        },
      });
      console.log(path, type);

      await Apis.putBanner(id, {
        thumbnail: path.replace("floatter", ""),
      });
      message.success("Success");

      setData((prev) => ({ ...prev, thumbnail: path }));
    } catch (error: any) {
      notification.error({
        message: error.response.data.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const putBannerInfo = (data: any) => {
    Apis.putBanner(id, data)
      .then((resolve) => {
        setData((prev) => {
          return { ...prev, ...data };
        });
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };

  const putMediaBanner = async (mimeType: string, file: UploadFile[]) => {
    setLoading(true);
    try {
      const type = mimeType.split("/")[0];
      const uploadResponse = await Apis.postFilesUpload(type, mimeType);
      const { path, url } = uploadResponse.data;

      await axios.put(url, file[0].originFileObj, {
        headers: {
          "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
        },
      });
      console.log(path, type);

      const bannerResponse = await Apis.putBanner(id, {
        type,
        image: path.replace("floatter", ""),
      });
      message.success("Success");
      setData((prev) => ({ ...prev, type, media: path }));
    } catch (error: any) {
      notification.error({
        message: error.response.data.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  // const approveBanner = () => {
  //   Apis.putBanner(id, { isApproved: true })
  //     .then((resolve) => {
  //       setData((prev) => {
  //         return { ...prev, isApproved: true };
  //       });
  //       message.success("해당 배너는 이제 활성화된 배너 목록에서 확인하실 수 있습니다.");
  //     })
  //     .catch((error) => {
  //       notification.error({
  //         message: error.response.data.message,
  //       });
  //     });
  // };

  const deleteBanner = () => {
    try {
      setLoading(true);
      Apis.deleteBannerById(id)
        .then((resolve) => {
          message.info("배너가 비활성화되었습니다.");
          navigate("/pages/banners/banner-list", { replace: true });
        })
        .catch((error) => {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Apis.getBannerById(id)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MediaInfo
              totalImpressions={data?.totalImpressions}
              totalClicks={data?.totalClicks}
              image={data?.image}
              type={data?.type}
              enabled={data?.enabled}
              putMediaBanner={putMediaBanner}
            />
          </Grid>

          <Grid item xs={12}>
            <BannerInfo
              weight={data?.weight}
              duration={data?.duration}
              putBannerInfo={putBannerInfo}
            />
          </Grid>
          {data?.type === "ssp" || data?.type === "live" ? null : (
            <Grid item xs={12}>
              <ThumbnailInfo thumbnail={data?.thumbnail} putThumbnail={putThumbnail} />
            </Grid>
          )}

          <Grid item xs={12}>
            <Expiration
              visibleFrom={data?.visibleFrom}
              visibleTo={data?.visibleTo}
              putVisibleTo={putVisibleTo}
            />
          </Grid>
          {data?.type === "ssp" ? null : (
            <Grid item xs={12}>
              <TargetLink target={data?.target} putTargetLink={putTargetLink} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">배너 비활성화</MDTypography>
                <MDTypography variant="button">
                  배너를 비활성화하며 취소 상태로 변경합니다. 승인 상태도 취소됩니다.
                </MDTypography>

                <Typography variant="h6" fontWeight="regular" textAlign="right">
                  <MDBox ml="auto" mt={1}>
                    <MDButton
                      variant="gradient"
                      color="error"
                      size="medium"
                      onClick={() => deleteBanner()}
                      disabled={loading}
                    >
                      배너 비활성화
                    </MDButton>
                  </MDBox>
                </Typography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default BannerDetails;
