/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { useEffect, useState } from "react";
import Apis from "apis/remotes";
import { IDashBoardByParam, WarningUser } from "apis/response";
import { Card, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

function Analytics(): JSX.Element {
  const navigate = useNavigate();
  const [data, setData] = useState<IDashBoardByParam>({
    totalUserCount: 0,
    todayActiveUser: 0,
    nowActiveUser: 0,
    todayBannerCount: 0,
    requestBannerCount: 0,
    todayApproveBannerCount: 0,
    giftishowBalance: 0,
    warningUser: [],
  });

  // Fetch dashboard data
  useEffect(() => {
    Apis.getDashBoardByParam()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
      });
  }, []);

  // interface WarningUser {
  //   userId: number;
  //   userName: string;
  //   warningType?: string[];
  //   isWarning?: number;
  //   clickCount: number;
  // }

  // 예시
  // "warningUser": [
  //   {
  //     "userId": 43,
  //     "userName": "김항래",
  //     "warningType": null,
  //     "isWarning": null,
  //     "clickCount": 14
  //   },
  //   {
  //     "userId": 77,
  //     "userName": "코토코챠",
  //     "warningType": null,
  //     "isWarning": null,
  //     "clickCount": 4
  //   },
  //   {
  //     "userId": 89,
  //     "userName": "네오렉트",
  //     "warningType": [
  //       "distribution"
  //     ],
  //     "isWarning": 1,
  //     "clickCount": 0
  //   },
  //   {
  //     "userId": 89,
  //     "userName": "네오렉트",
  //     "warningType": [
  //       "interval"
  //     ],
  //     "isWarning": 0,
  //     "clickCount": 0
  //   }
  // ]

  const tableColumn: ColumnsType<WarningUser> = [
    {
      title: "ID",
      dataIndex: "userId",
      key: "userId",
      ellipsis: true,
    },
    {
      title: "유저 이름",
      dataIndex: "userName",
      key: "userName",
      ellipsis: true,
    },
    {
      title: "경고 유형",
      dataIndex: "warningType",
      key: "warningType",
      ellipsis: true,
      render: (warningType?: string[]) =>
        warningType && warningType.length > 0 ? warningType.join(", ") : "없음",
    },
    {
      title: "경고 여부",
      dataIndex: "isWarning",
      key: "isWarning",
      ellipsis: true,
      render: (isWarning?: number) => (isWarning === 1 ? "경고 있음" : "경고 없음"),
    },
    {
      title: "클릭 횟수",
      dataIndex: "clickCount",
      key: "clickCount",
      ellipsis: true,

      render: (clickCount: number) => clickCount.toLocaleString(),
    },
  ];

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <ComplexStatisticsCard
              color="primary"
              icon="group"
              title="전체 회원 수"
              count={data.totalUserCount.toLocaleString()}
              percentage={{
                color: "success",
                amount: "",
                label: "전체 가입된 회원 수",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ComplexStatisticsCard
              color="success"
              icon="person"
              title="오늘 활성 유저"
              count={data.todayActiveUser.toLocaleString()}
              percentage={{
                color: "success",
                amount: "",
                label: "오늘 활동 중",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ComplexStatisticsCard
              color="info"
              icon="visibility"
              title="현재 활성 유저"
              count={data.nowActiveUser.toLocaleString()}
              percentage={{
                color: "success",
                amount: "",
                label: "현재 활동 중",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ComplexStatisticsCard
              color="warning"
              icon="banner"
              title="오늘 배너 수"
              count={data.todayBannerCount.toLocaleString()}
              percentage={{
                color: "info",
                amount: "",
                label: "오늘 노출된 배너",
              }}
            />
          </Grid>
        </Grid>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <ComplexStatisticsCard
                color="secondary"
                icon="assignment"
                title="요청 배너 수"
                count={data.requestBannerCount.toLocaleString()}
                percentage={{
                  color: "error",
                  amount: "",
                  label: "전체 요청 배너",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <ComplexStatisticsCard
                color="dark"
                icon="check_circle"
                title="오늘 승인된 배너"
                count={data.todayApproveBannerCount.toLocaleString()}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <ComplexStatisticsCard
                color="primary"
                icon="account_balance_wallet"
                title="기프티쇼 잔액"
                count={`${data.giftishowBalance.toLocaleString()}원`}
                percentage={{
                  color: "info",
                  amount: "",
                  label: "남은 잔액",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Table
                  title={() => "비정상 사용 유저"}
                  rowKey={(record) => record.userId}
                  scroll={{ x: "max-content" }}
                  style={{ cursor: "pointer" }}
                  dataSource={data.warningUser}
                  columns={tableColumn}
                  onRow={(record, _) => {
                    return {
                      onClick: () => {
                        navigate(`/page/user/detail-user/${record.userId}`);
                      },
                    };
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
