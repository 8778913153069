/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { InboxOutlined } from "@mui/icons-material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";

import LiveBannerImg from "assets/images/fltt/live_banner.png";
import SSPBannerImg from "assets/images/fltt/ssp_banner.jpg";

import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import Dragger from "antd/es/upload/Dragger";
import { Button, Upload, message } from "antd";

const props: UploadProps = {
  name: "file",
  multiple: false,
  maxCount: 1,
  beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
    const isMp4 = file.type === "video/mp4";

    if (!isJpgOrPng && !isMp4) {
      message.error("You can only upload JPG/PNG/JPEG image and MP4 video files!");
      return Upload.LIST_IGNORE; // 이 파일을 업로드 목록에서 제외합니다.
    }

    return true; // 파일 형식이 조건에 맞으면 업로드를 계속 진행합니다.
  },
  customRequest({ file, onSuccess }) {
    const res = "Ok";
    onSuccess!(res);
  },
  // onChange(info) {
  //   const { status } = info.file;
  //   if (status !== "uploading") {
  //     console.log(info.file, info.fileList);
  //   }
  //   if (status === "done") {
  //     message.success(`${info.file.name} file uploaded successfully.`);
  //   } else if (status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  // },
  // onDrop(e) {
  //   console.log("Dropped files", e.dataTransfer.files);
  // },
};

interface Props {
  image: string;
  type: string;
  enabled: number;
  putMediaBanner: Function;
}

function MediaInfo({ image, type, enabled, putMediaBanner }: Props): JSX.Element {
  const [data, setData] = useState({ image, type, enabled });
  const [file, setFile] = useState<UploadFile[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [hasError, setHasError] = useState(false);

  const handleClickEditButton = () => {
    setOpen((prev) => !prev);
  };

  const handleClickUpdateMedia = () => {
    const mimeType = file[0].type;
    putMediaBanner(mimeType, file).finally(() => {
      setOpen(false);
    });
  };

  const onChangeMusicFile: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setFile(newupdatedFileList);
  };

  useEffect(() => {
    setData({
      image,
      type,
      enabled,
    });
  }, [image, type, enabled]);

  // 렌더링할 콘텐츠 결정
  const renderContent = () => {
    if (data?.type === "image" || data?.type === "video" || data?.type === "shorts") {
      return (
        <MDBox
          component="video"
          muted
          autoPlay
          loop
          src={`${process.env.REACT_APP_IMG_ENDPOINT}${data?.image}`}
          onError={() => setHasError(true)}
          alt="Banner Thumbnail"
          borderRadius="lg"
          shadow="sm"
          width={160}
          position="relative"
          zIndex={10}
          mb={2}
        />
      );
    } else if (data?.type === "live") {
      return (
        <MDBox
          component="img"
          src={LiveBannerImg}
          alt="Banner Thumbnail"
          borderRadius="lg"
          shadow="sm"
          width={160}
          position="relative"
          zIndex={10}
          mb={2}
        />
      );
    } else if (data?.type === "ssp") {
      return (
        <MDBox
          component="img"
          src={SSPBannerImg}
          alt="Banner Thumbnail"
          borderRadius="lg"
          shadow="sm"
          width={56}
          position="relative"
          zIndex={10}
          mb={2}
        />
      );
    } else {
      return (
        <MDBox
          component="img"
          src={`${process.env.REACT_APP_IMG_ENDPOINT}${data?.image}`}
          onError={() => setHasError(true)}
          alt="Banner Thumbnail"
          borderRadius="lg"
          shadow="sm"
          width="100%"
          height="100%"
          position="relative"
          zIndex={10}
          mb={2}
        />
      );
    }
  };
  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2} className="card-header">
        {!hasError ? (
          renderContent()
        ) : (
          <MDBox
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={160} // 높이는 필요에 따라 조정
            backgroundColor="gray"
            borderRadius="lg"
          >
            <span>Error loading content</span>
          </MDBox>
        )}
      </MDBox>
      <MDBox textAlign="center" pt={0} pb={3} px={3}>
        <MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <Typography variant="h6" fontWeight="light">
              타입
            </Typography>
            <Typography variant="h6" fontWeight="regular">
              {data?.type}
            </Typography>
          </MDBox>

          <Typography variant="h6" fontWeight="regular" textAlign="right">
            <MDBadge
              variant="contained"
              size="xs"
              badgeContent={data?.enabled ? "광고 송출" : "광고 중지"}
              color={data?.enabled ? "success" : "warning"}
              container
            />
          </Typography>
          {/* <MDBox mt={1}>
            <Typography variant="h6" fontWeight="regular" textAlign="right">
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  onClick={handleClickEditButton}
                >
                  수정하기
                </MDButton>
              </MDBox>
            </Typography>
          </MDBox> */}
        </MDBox>
      </MDBox>
      {/* {open && (
        <MDBox p={3}>
          <Divider />
          <MDBox>
            <Dragger
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              accept="image/png,image/jpeg,image/jpg,video/mp4"
              fileList={file}
              onChange={onChangeMusicFile}
              {...props}
            >
              {file.length === 0 ? (
                <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company
                    data or other banned files.
                  </p>
                </>
              ) : (
                <Button block disabled>
                  only one Banner pasible
                </Button>
              )}
            </Dragger>
          </MDBox>
          <MDBox ml="auto" mt={1}>
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              fullWidth
              disabled={file.length === 0}
              onClick={() => handleClickUpdateMedia()}
            >
              update media banner
            </MDButton>
          </MDBox>
        </MDBox>
      )} */}
    </Card>
  );
}

export default MediaInfo;
