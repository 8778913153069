// @mui material components
import Card from "@mui/material/Card";
// import Typography from "@mui/material/Typography";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDDatePicker from "components/MDDatePicker";
import { useEffect, useState } from "react";

import dayjs from "dayjs";

interface Props {
  visibleFrom: string;
  visibleTo: any;
  putVisibleTo: Function;
}
function Expiration({ visibleFrom, visibleTo, putVisibleTo }: Props): JSX.Element {
  // const [_visibleTo, setVisibleTo] = useState<Date>(new Date(visibleTo));

  // const handleDateChange = (newDate: Date) => {
  //   setVisibleTo(new Date(newDate));
  // };

  // const handleClickUpdate = () => {
  //   if (_visibleTo) {
  //     putVisibleTo(_visibleTo);
  //   } else {
  //     putVisibleTo(_visibleTo);
  //   }
  // };

  // useEffect(() => {
  //   setVisibleTo(visibleTo ? new Date(visibleTo) : null);
  // }, [visibleTo]);
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">요청 광고 시간</MDTypography>
        <MDBox mt={2}>
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              노출 시작일(Vislble From)
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {dayjs(visibleFrom).format("YYYY년 MM월 DD일 HH:mm:ss")}
            </MDTypography>
          </MDBox>

          <MDBox
            mt={2}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              노출 종료일(Visible To)
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {visibleTo ? dayjs(visibleTo).format("YYYY년 MM월 DD일 HH:mm:ss") : "상시 노출"}
            </MDTypography>
            {/* <MDDatePicker value={_visibleTo} onChange={handleDateChange} /> */}
          </MDBox>
        </MDBox>

        {/* <MDBox ml="auto" mt={1}>
          <Typography variant="h6" fontWeight="regular" textAlign="right">
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={() => handleClickUpdate()}
              >
                수정하기
              </MDButton>
            </MDBox>
          </Typography>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default Expiration;
