import { ColumnsType } from "antd/es/table";
import { IUser } from "apis/response";
import MDBox from "components/MDBox";
import dayjs from "dayjs";

const tableColumn: ColumnsType<IUser> = [
  {
    title: "이메일",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (email: string) => <MDBox style={{ maxWidth: 200 }}>{email}</MDBox>,
  },
  {
    title: "연락처",
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (phone: string) => <MDBox style={{ maxWidth: 150 }}>{phone}</MDBox>,
  },
  {
    title: "가입유형",
    dataIndex: "type",
    key: "type",
    ellipsis: true,
    render: (type: string) => <MDBox style={{ maxWidth: 100 }}>{type}</MDBox>,
  },
  {
    title: "상태",
    dataIndex: "status",
    key: "status",
    ellipsis: true,
    render: (status: string) => <MDBox style={{ maxWidth: 100 }}>{status}</MDBox>,
  },
  {
    title: "SMS 동의",
    dataIndex: "smsConsent",
    key: "smsConsent",
    ellipsis: true,
    align: "center",
    render: (smsConsent: boolean) => (
      <MDBox style={{ maxWidth: 100 }}>{smsConsent ? "동의" : "비동의"}</MDBox>
    ),
  },
  {
    title: "가입일자",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
    sorter: true,
    render: (createdAt: string) => dayjs(createdAt).format("YYYY년 MM월 DD일"),
  },
  {
    title: "포인트",
    dataIndex: "point",
    key: "point",
    align: "end",
    ellipsis: true,
    sorter: true,
    render: (point: number) => <MDBox style={{ maxWidth: 100 }}>{point}</MDBox>,
  },
  {
    title: "경고 횟수",
    dataIndex: "warningCount",
    key: "warningCount",
    align: "end",
    ellipsis: true,
    sorter: true,
    render: (warningCount: number) => <MDBox style={{ maxWidth: 100 }}>{warningCount}</MDBox>,
  },
];

export { tableColumn };
