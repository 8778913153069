import { message } from "antd";
import axios from "axios";

const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.timeout = 2500;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 2500,
});
api.interceptors.response.use(
  (response) => response, // 성공 응답 처리
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          message.error("세션이 만료되었습니다. 다시 로그인해주시기바랍니다.");
          // 히스토리 스택 모두 제거
          window.location.replace("/authentication/sign-in/basic");
          break;
        case 498:
          break;

        case 477:
          message.error(`${error.response.data}`);
          break;
        default:
          message.error(`${error.response.data.message}`);
          break;
      }
    } else {
      message.error(`${error.response.data.message}`);
    }

    return Promise.reject(error);
  }
);

export { initAxios, api };
