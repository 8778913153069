/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { Card, Form, Input } from "antd";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

interface Props {
  loading: boolean;
  approveBanner: Function;
  rejectBanner: (rejectReason: string) => void;
}

// 배너 거절 Form하나 만들어서 rejectBanner를 수행함. 이때 rejectBanner는 rejectReason:string 값을 가져와서 전달.
// form은 textArea 그리고 거절버튼으로 구성됨.
function BannerApproveCard({ loading, approveBanner, rejectBanner }: Props): JSX.Element {
  const [form] = Form.useForm();

  const handleReject = () => {
    form
      .validateFields()
      .then((values) => {
        rejectBanner(values.rejectReason); // Pass reject reason to the rejectBanner function
        form.resetFields(); // Reset form after submission
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };
  return (
    <MDBox display="flex" flexDirection="column" gap={2}>
      <Card className="p-3">
        <MDTypography color="secondary" fontSize="small" mb={2}>
          해당 배너는 광고주의 요청에 의해 결제 예약 대기중입니다. 배너를 노출과 동시에 결제가
          시작됩니다.
        </MDTypography>

        <MDButton
          color="info"
          variant="contained"
          onClick={() => approveBanner()}
          disabled={loading}
        >
          배너 결제 및 노출 활성화
        </MDButton>
      </Card>
      <Card>
        <Form form={form} layout="vertical">
          <Form.Item
            name="rejectReason"
            label="거절 사유"
            rules={[
              { required: true, message: "거절 사유를 입력해주세요." },
              { max: 500, message: "거절 사유는 500자 이내로 입력해주세요." },
            ]}
          >
            <Input.TextArea rows={4} placeholder="거절 사유를 입력해주세요." />
          </Form.Item>
          <Form.Item>
            <MDButton
              fullWidth
              color="error"
              variant="contained"
              onClick={handleReject}
              disabled={loading}
            >
              배너 거절
            </MDButton>
          </Form.Item>
        </Form>
      </Card>
    </MDBox>
  );
}

export default BannerApproveCard;
