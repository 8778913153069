import { IPostPointReward } from "apis/request";
import {
  IGetBanner,
  IGetConfigure,
  getList,
  IGetBannerById,
  IGetFilesUpload,
  INotice,
  IGetBannerRequestTest,
  IUser,
  IUserById,
  IGift,
  IGiftBizMoney,
  IGiftInvalid,
  IDashBoardByParam,
} from "apis/response";
import { api } from "apis/axios";

const Apis = {
  /** 설문조사 리워드 포인트 생성 */
  postPointReward(option: IPostPointReward) {
    return api.post(`point/reward`, option);
  },

  /** 로그인 세션 체크 */
  getAuth() {
    return api.get("auth");
  },
  /** 관리자 로그인 */
  postAuth(option: any) {
    return api.post("auth", option);
  },
  /** 관리자 로그아웃 */
  deleteAuth() {
    return api.delete(`auth`);
  },

  /** Get pre-signed url */
  postFilesUpload(type: string, mimeType: string) {
    return api.get<IGetFilesUpload>(`files/upload`, {
      params: {
        type,
        mimeType,
      },
    });
  },

  /** 광고 거절 */
  putBannerReject(option: any) {
    return api.put(`banner/reject`, option);
  },

  /** 배너 전체 목록 조회 */
  getBanner(option: any) {
    return api.get<getList<IGetBanner>>("banner", { params: option });
  },

  /** 배너 상세 정보 조회 */
  getBannerById(id: string | number) {
    return api.get<IGetBannerById>(`banner/${id}`);
  },

  /** 자체 광고 배너 수정 */
  putBannerById(id: string | number, option: any) {
    return api.put(`banner/${id}`, option);
  },

  /** 자체 광고 배너 비활성화(취소) */
  deleteBannerById(id: string | number) {
    return api.delete(`banner/${id}`);
  },

  /** 요청 배너 목록을 조회합니다.(승인 대기중) */
  getBannerRequestList(option: any) {
    return api.get<getList<IGetBannerRequestTest>>(`banner/request/list`, { params: option });
  },

  /** 광고 승인 및 결제 */
  postBannerPayment(option: any) {
    return api.post(`banner/payment`, option);
  },

  /** 설정 값 변경
   *
   * key: 변경할 설정 값의 키
   * value: 변경할 설정 값
   * bool 타입, 스트링, 숫자 등 다양한 타입을 가지고 있으니 주의 필요
   */
  putConfig(option: any) {
    return api.put("config", option);
  },
  /** 띄우미 설정 조회 */
  getConfig() {
    return api.get<IGetConfigure>("config");
  },

  /** 대시보드 조회
   *
   * param: ex) 2024-12-01
   */
  getDashBoardByParam() {
    return api.get<IDashBoardByParam>(`dashboard/2024-12-01`);
  },

  /** Ad Selector 호출 */
  putAdSelector() {
    return api.put(`ad/selector`);
  },

  /** 고객 상세 정보 수정 */
  putClient(id: string | number, option: any) {
    return api.put(`client/${id}`, option);
  },

  /** 자체 광고 배너 수정 */
  putBanner(id: string | number, option: any) {
    return api.put(`banner/${id}`, option);
  },

  /** 자체 광고 배너 비활성화 */
  deleteBanner(id: any) {
    return api.delete(`banner/${id}`);
  },

  /** 공지사항 목록 조회 */
  getNotices(option: any) {
    return api.get<getList<INotice>>(`notice`, { params: option });
  },

  /** 공지사항 상세 조회 */
  getNoticeById(id: string | number) {
    return api.get<INotice>(`notice/${id}`);
  },

  /** 공지사항 등록 */
  postNotice(option: any) {
    return api.post(`notice`, option);
  },

  /** 공지사항 수정 */
  putNoticeById(id: string | number, option: any) {
    return api.put(`notice/${id}`, option);
  },

  /** 공지사항 삭제 */
  deleteNoticeById(id: string | number) {
    return api.delete(`notice/${id}`);
  },

  /** 회원 조회 */
  getUser(option: any) {
    return api.get<getList<IUser>>("user", { params: option });
  },

  /** 회원 상세 조회 */
  getUserById(id: string | number) {
    return api.get<IUserById>(`user/${id}`);
  },

  /** 기프티쇼 상품 추가 */
  postGift(option: any) {
    return api.post(`gift`, option);
  },

  /** 기프트쇼 상품 조회 */
  getGift(option: any) {
    return api.get<getList<IGift>>(`gift`, { params: option });
  },
  /** 기프트쇼 상품 내리기(수동) */
  putGiftById(id: string | number) {
    return api.put(`gift/${id}`);
  },
  /** 기프트쇼 상품 유효성 검사
   * api 이용 불가 목록 반환
   */
  getGiftInvalid() {
    return api.get<getList<IGiftInvalid>>(`gift/invalid`);
  },
  /** 비즈 포인트 잔액 조회 */
  getGiftBizMoney() {
    return api.get<IGiftBizMoney>(`gift/bizmoney`);
  },
};

export default Apis;
