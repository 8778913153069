import Apis from "apis/remotes";
import { IGetBannerById } from "apis/response";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MediaInfo from "./components/MediaInfo";
import ThumbnailInfo from "./components/ThumbnailInfo";
import Expiration from "./components/Expiration";
import TargetLink from "./components/TargetLink";
import { App, UploadFile } from "antd";
import axios from "axios";
import { convertDateToYYYYMMDD } from "utils/utilDate";
import BannerInfo from "./components/BannerInfo";
import BannerApproveCard from "./components/BannerApproveCard";

function RequestBannerDetails(): JSX.Element {
  const navigate = useNavigate();
  const { message, notification } = App.useApp();
  const { id } = useParams();
  const [data, setData] = useState<IGetBannerById>();
  const [loading, setLoading] = useState<boolean>(false);

  const putTargetLink = (target: string) => {
    Apis.putBanner(id, { target })
      .then((resolve) => {
        setData((prev) => {
          return { target, ...prev };
        });
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };
  const putVisibleTo = (option: Date | null) => {
    let visibleTo = convertDateToYYYYMMDD(option);

    Apis.putBanner(id, { visibleTo })
      .then((resolve) => {
        setData((prev) => {
          return { visibleTo, ...prev };
        });
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };

  const putThumbnail = async (mimeType: string, file: UploadFile[]) => {
    setLoading(true);
    try {
      const type = mimeType.split("/")[0];
      const uploadResponse = await Apis.postFilesUpload(type, mimeType);
      const { path, url } = uploadResponse.data;

      await axios.put(url, file[0].originFileObj, {
        headers: {
          "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
        },
      });
      console.log(path, type);

      await Apis.putBanner(id, {
        thumbnail: path.replace("floatter", ""),
      });
      message.success("Success");

      setData((prev) => ({ ...prev, thumbnail: path }));
    } catch (error: any) {
      notification.error({
        message: error.response.data.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const putBannerInfo = (data: any) => {
    Apis.putBanner(id, data)
      .then((resolve) => {
        setData((prev) => {
          return { ...prev, ...data };
        });
        message.success("Success");
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
  };

  const putMediaBanner = async (mimeType: string, file: UploadFile[]) => {
    setLoading(true);
    try {
      const type = mimeType.split("/")[0];
      const uploadResponse = await Apis.postFilesUpload(type, mimeType);
      const { path, url } = uploadResponse.data;

      await axios.put(url, file[0].originFileObj, {
        headers: {
          "Content-Type": mimeType, // 여기서 mimeType 전체를 사용해야 올바른 Content-Type이 설정됩니다.
        },
      });
      console.log(path, type);

      const bannerResponse = await Apis.putBanner(id, {
        type,
        image: path.replace("floatter", ""),
      });
      message.success("Success");
      setData((prev) => ({ ...prev, type, media: path }));
    } catch (error: any) {
      notification.error({
        message: error.response.data.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const approveBanner = () => {
    try {
      setLoading(true);
      Apis.postBannerPayment({ bannerId: id })
        .then((resolve) => {
          setData((prev) => {
            return { ...prev, isApproved: true };
          });
          message.success("해당 배너는 이제 활성화된 배너 목록에서 확인하실 수 있습니다.");
          navigate("/pages/banners/banner-list");
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /** 요청 배너 거절 */
  const rejectBanner = (rejectReason: string) => {
    try {
      setLoading(true);
      Apis.putBannerReject({
        bannerId: id,
        rejectReason: rejectReason,
      })
        .then((resolve) => {
          setData((prev) => {
            return { ...prev, isApproved: false };
          });
          message.success("해당 배너는 거절되었습니다.");
          navigate("/pages/banners/request-banner-list");
        })
        .catch((error) => {
          notification.error({
            message: error.response.data.message,
          });
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Apis.getBannerById(id)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8}>
            <MediaInfo
              image={data?.image}
              type={data?.type}
              enabled={data?.enabled}
              putMediaBanner={putMediaBanner}
            />
          </Grid>
          {data?.status === "pending" && (
            <Grid item xs={12} lg={4}>
              <BannerApproveCard
                loading={loading}
                approveBanner={approveBanner}
                rejectBanner={rejectBanner}
              />
            </Grid>
          )}
          <Grid item xs={12} lg={8}>
            <BannerInfo
              weight={data?.weight}
              duration={data?.duration}
              putBannerInfo={putBannerInfo}
            />
          </Grid>
          {data?.type === "ssp" || data?.type === "live" ? null : (
            <Grid item xs={12} lg={8}>
              <ThumbnailInfo thumbnail={data?.thumbnail} putThumbnail={putThumbnail} />
            </Grid>
          )}

          <Grid item xs={12} lg={8}>
            <Expiration
              visibleFrom={data?.visibleFrom}
              visibleTo={data?.visibleTo}
              putVisibleTo={putVisibleTo}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <TargetLink target={data?.target} putTargetLink={putTargetLink} />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default RequestBannerDetails;
