import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

import LiveBannerImg from "assets/images/fltt/live_banner.png";
import SSPBannerImg from "assets/images/fltt/ssp_banner.jpg";

import Apis from "apis/remotes";
import { IGetBanner } from "apis/response";
import { message, Pagination, PaginationProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { paramsFilter } from "utils/ParamsFilter";

function BannerList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page") ? Number(params.get("page")) : 1;

  // const [currentPage, setCurrentPage] = useState(Number(page ?? 1));
  const [data, setData] = useState<IGetBanner[]>([]);
  const [total, setTotal] = useState(0);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      `/pages/banners/banner-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };

  const putAdSelector = () => {
    Apis.putAdSelector()
      .then((resolve) => {
        message.success("수동db 변경적용에 성공하였습니다.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    Apis.getBanner({ page: page, perPage: 10, search, sort, order })
      .then((resolve) => {
        setData(resolve.data.list);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, search, sort, order]);

  const columns: ColumnsType<IGetBanner> = [
    {
      title: "배너 ID",
      dataIndex: "bannerId",
      key: "bannerId",
      align: "right",
    },
    {
      title: "배너",
      dataIndex: "image",
      key: "image",
      render: (image: string, record: IGetBanner) => {
        const src = process.env.REACT_APP_IMG_ENDPOINT + image;
        switch (record.type) {
          case "ssp":
            return <img src={SSPBannerImg} width={58} height={47} />;
            break;
          case "live":
            return <img src={LiveBannerImg} width={80} height={128} />;
            break;
          default:
            return <video src={src} width={80} height={128} controls={false} />;
            break;
        }
      },
    },
    {
      title: "광고주 이름",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "유형",
      dataIndex: "type",
      key: "type",
      render: (type: string) => type.toUpperCase(),
    },
    {
      title: "요청 광고 시간",
      dataIndex: "visibleFrom",
      key: "visibleFrom",
      render: (visibleFrom: string, record: IGetBanner) => {
        const visibleFromDate = dayjs(visibleFrom).isValid()
          ? dayjs(visibleFrom).format("YYYY년 MM월 DD일 HH시 mm분")
          : "즉시";
        const visibleToData = dayjs(record.visibleTo).isValid()
          ? dayjs(record.visibleTo).format("YYYY년 MM월 DD일 HH시 mm분")
          : "상시 노출";

        return `${visibleFromDate} - ${visibleToData}`;
      },
    },
    // {
    //   title: "종료일",
    //   dataIndex: "visibleTo",
    //   key: "visibleTo",
    //   render: (visibleTo: string) => {
    //     const date = dayjs(visibleTo);
    //     return date.isValid() ? date.format("YYYY년 MM월 DD일 HH시 mm분") : "상시";
    //   },
    // },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "플랜",
      dataIndex: "plan",
      key: "plan",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MDButton
          color="info"
          variant="gradient"
          onClick={() => putAdSelector()}
          sx={{ fontSize: "18px" }}
        >
          수동 DB 변경 적용
        </MDButton>
      </MDBox>
      <MDBox my={3}>
        <Card>
          <Table
            pagination={false}
            scroll={{ x: "max-content" }}
            dataSource={data}
            columns={columns}
            style={{ cursor: "pointer" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/page/banners/detail-banner/${record.bannerId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={page}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default BannerList;
