/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";

import SignInBasic from "layouts/authentication/sign-in/basic";
import ClientList from "layouts/pages/clients/client-list";
import BannerList from "layouts/pages/banners/banner-list";
import ConfigureSetting from "layouts/configureSetting";

// @mui icons
import Icon from "@mui/material/Icon";

// Images

import BannerDetails from "layouts/pages/banners/banner-details";
import RequestBannerList from "layouts/pages/banners/request-banner-list";

// Notices
import NewNotice from "layouts/pages/notices/new-notice";
import NoticeList from "layouts/pages/notices/notice-list";
import NoticeDetails from "layouts/pages/notices/notice-details";
import UpdateNotice from "layouts/pages/notices/update-notice";
import UserList from "layouts/pages/users/user-list";
import UserDetails from "layouts/pages/users/user-details";
import GiftList from "layouts/pages/gifts/gift-list";
import NewGift from "layouts/pages/gifts/new-gift";
import RequestBannerDetails from "layouts/pages/banners/request-banner-details";

const routes = [
  {
    type: "collapse",
    name: "배너",
    key: "banner",
    icon: <Icon fontSize="medium">ad_units</Icon>,
    collapse: [
      {
        name: "배너 목록",
        key: "banner-list",
        route: "/pages/banners/banner-list",
        component: <BannerList />,
      },
      // {
      //   type: "collapse",
      //   name: "배너 추가",
      //   key: "new-banner",
      //   collapse: [
      //     {
      //       name: "가로 배너",
      //       key: "banner-list",
      //       route: "/page/banners/new-banner",
      //       component: <NewBanner />,
      //     },
      //     {
      //       name: "세로 배너",
      //       key: "new-banner-shorts",
      //       route: "/page/banners/new-banner-shorts",
      //       component: <NewBannerShorts />,
      //     },
      //     {
      //       name: "라이브 배너",
      //       key: "new-banner-live",
      //       route: "/page/banners/new-banner-live",
      //       component: <NewBannerLive />,
      //     },
      //   ],
      // },
      {
        name: "배너 상세 페이지",
        key: "detail-banner",
        route: "/page/banners/detail-banner/:id",
        component: <BannerDetails />,
        hidden: true,
      },
      {
        name: "요청 배너 목록",
        key: "request-banner-list",
        route: "/pages/banners/request-banner-list",
        component: <RequestBannerList />,
      },
      {
        name: "요청 배너 상세 페이지",
        key: "detail-request-banner",
        route: "/pages/banners/detail-request-banner/:id",
        component: <RequestBannerDetails />,
        hidden: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "공지사항",
    key: "notice",
    icon: <Icon fontSize="medium">notifications_activeIcon</Icon>,
    collapse: [
      {
        name: "공지사항 목록",
        key: "notice-list",
        route: "/pages/notice/notice-list",
        component: <NoticeList />,
      },
      {
        name: "공지사항 추가",
        key: "new-notice",
        route: "/page/notice/new-notice",
        component: <NewNotice />,
        hidden: true,
      },
      {
        name: "공지사항 수정",
        key: "update-notice",
        route: "/page/notice/update-notice/:id",
        component: <UpdateNotice />,
        hidden: true,
      },
      {
        name: "공지사항 상세 페이지",
        key: "detail-notice",
        route: "/page/notice/detail-notice/:id",
        component: <NoticeDetails />,
        hidden: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "회원관리",
    key: "user",
    icon: <Icon fontSize="medium">notifications_activeIcon</Icon>,
    collapse: [
      {
        name: "회원 목록",
        key: "user-list",
        route: "/pages/user/user-list",
        component: <UserList />,
      },
      {
        name: "회원 상세 페이지",
        key: "detail-user",
        route: "/page/user/detail-user/:id",
        component: <UserDetails />,
        hidden: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "기프티쇼 관리",
    key: "gift",
    icon: <Icon fontSize="medium">notifications_activeIcon</Icon>,
    collapse: [
      {
        name: "기프티쇼 목록",
        key: "gift-list",
        route: "/pages/gift/gift-list",
        component: <GiftList />,
      },
      {
        name: "기프티쇼 등록 페이지",
        key: "new-gift",
        route: "/page/gift/new-gift",
        component: <NewGift />,
        hidden: true,
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "클라이언트 목록",
  //   key: "client-list",
  //   route: "/pages/client-list",
  //   component: <ClientList />,
  //   icon: <Icon fontSize="medium">recent_actors</Icon>,
  //   noCollapse: true,
  // },

  {
    type: "collapse",
    name: "설정",
    key: "configure-setting",
    route: "/configure-setting",
    icon: <Icon fontSize="medium">settings</Icon>,
    component: <ConfigureSetting />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "대시보드",
    key: "analytics",
    route: "/dashboards/analytics",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Analytics />,
    noCollapse: true,
  },

  {
    // type: "collapse",
    name: "인증",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "로그인",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
            hidden: true,
          },
        ],
      },
    ],
  },
];

export default routes;
