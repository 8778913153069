/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import FormField from "../FormField";

interface Props {
  weight: number;
  duration: number;
  putBannerInfo: Function;
}
function BannerInfo({ weight, duration, putBannerInfo }: Props): JSX.Element {
  const [data, setData] = useState({ weight, duration });

  const handleClickUpdate = () => {
    putBannerInfo(data);
  };

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.valueAsNumber });
  };

  useEffect(() => {
    setData({ weight, duration });
  }, [weight, duration]);
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">배너 가중치 및 지속시간</MDTypography>
        <MDBox mt={2}>
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              가중치
            </MDTypography>
            <MDBox mt={1}>
              <FormField
                type="number"
                label="weight"
                name="weight"
                value={data.weight}
                onChange={handleChangeData}
              />
            </MDBox>
          </MDBox>
          <MDBox
            mt={2}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              지속시간(초)
            </MDTypography>
            <MDBox mt={1}>
              <FormField
                type="number"
                label="duration"
                name="duration"
                value={data.duration}
                onChange={handleChangeData}
              />
            </MDBox>
          </MDBox>
        </MDBox>

        <MDBox ml="auto" mt={1}>
          <Typography variant="h6" fontWeight="regular" textAlign="right">
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                onClick={() => handleClickUpdate()}
              >
                수정하기
              </MDButton>
            </MDBox>
          </Typography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BannerInfo;
