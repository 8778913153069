import { ColumnsType } from "antd/es/table";
import { INotice } from "apis/response";
import MDBox from "components/MDBox";
import dayjs from "dayjs";

const tableColumn: ColumnsType<INotice> = [
  {
    title: "제목",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    render: (title: string) => <MDBox style={{ maxWidth: 200 }}>{title}</MDBox>,
  },
  {
    title: "내용",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
    render: (content: string) => <MDBox style={{ maxWidth: 300 }}>{content}</MDBox>,
  },
  {
    title: "작성시간",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
    render: (createdAt: string) => dayjs(createdAt).format("YYYY년 MM월 DD일 HH시 mm분 ss초"),
  },
];
export { tableColumn };
