import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

import { Pagination, PaginationProps, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useLocation, useNavigate } from "react-router-dom";
import Apis from "apis/remotes";
import { IGetBannerRequestTest, PLANTYPE, STATUSTYPE } from "apis/response";
// import MDButton from "components/MDButton";
import dayjs from "dayjs";
import { paramsFilter } from "utils/ParamsFilter";

function RequestBannerList(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const search = params.get("search");
  const sort = params.get("sort");
  const order = params.get("order");
  const page = params.get("page") ? Number(params.get("page")) : 1;

  const [data, setData] = useState<IGetBannerRequestTest[]>([]);
  const [total, setTotal] = useState(0);

  const columns: ColumnsType<IGetBannerRequestTest> = [
    {
      title: "ID",
      dataIndex: "bannerId",
      key: "bannerId",
    },
    {
      title: "타입",
      dataIndex: "type",
      key: "type",
    },
    // {
    //   title: "활성화 여부",
    //   dataIndex: "enabled",
    //   key: "enabled",
    //   render: (enabled: number) => (
    //     <Tag color={enabled ? "green" : "red"}>{enabled ? "활성화" : "비활성화"}</Tag>
    //   ),
    // },

    {
      title: "요청 광고 시간",
      dataIndex: "visibleFrom",
      key: "visibleFrom",
      render: (visibleFrom: string, record: IGetBannerRequestTest) => {
        const visibleFromDate = dayjs(visibleFrom).isValid()
          ? dayjs(visibleFrom).format("YYYY년 MM월 DD일 HH시 mm분")
          : "즉시";
        const visibleToData = dayjs(record.visibleTo).isValid()
          ? dayjs(record.visibleTo).format("YYYY년 MM월 DD일 HH시 mm분")
          : "상시 노출";

        return `${visibleFromDate} - ${visibleToData}`;
      },
    },
    {
      title: "광고주 이름",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (status: STATUSTYPE) => {
        // 상태에 따른 색상 및 텍스트 정의
        const statusMap: Record<STATUSTYPE, { color: string; text: string }> = {
          pending: { color: "gold", text: "심사 대기중" },
          approving: { color: "blue", text: "심사 승인" },
          running: { color: "green", text: "심사 승인 이후 노출중" },
          rejecting: { color: "red", text: "심사 승인 거절" },
          completing: { color: "gray", text: "광고 노출 만료" },
          canceling: { color: "orange", text: "심사 대기중 광고 취소" },
        };

        // 기본값 처리 (정의되지 않은 상태)
        const { color = "default", text = status } = statusMap[status] || {};

        return <Tag color={color}>{text}</Tag>;
      },
    },

    {
      title: "플랜",
      dataIndex: "plan",
      key: "plan",
      render: (plan: PLANTYPE) => {
        // 플랜에 따른 색상 및 텍스트 정의
        const planMap: Record<PLANTYPE, { color: string; text: string }> = {
          standard: { color: "blue", text: "스탠다드" },
          premium: { color: "gold", text: "프리미엄" },
          hour: { color: "purple", text: "라이브" },
          cheap: { color: "red", text: "할인광고(이벤트)" },
          free: { color: "green", text: "무료광고(이벤트)" },
        };

        // 기본값 처리
        const { color = "default", text = plan } = planMap[plan] || {};

        return <Tag color={color}>{text}</Tag>;
      },
    },

    // {
    //   title: "노출 비율",
    //   dataIndex: "weight",
    //   key: "weight",
    // },
    // {
    //   title: "지속 시간 (초)",
    //   dataIndex: "duration",
    //   key: "duration",
    // },
    // {
    //   title: "광고 노출 관리",
    //   key: "action",
    //   render: (_, record) =>
    //     record.status === "pending" ? (
    //       <MDButton
    //         color="info"
    //         variant="gradient"
    //         loading={loadingBannerId === record.bannerId} // 로딩 상태일 때 로딩 스피너 표시
    //         onClick={() => handleStartBanner(record.bannerId)}
    //       >
    //         광고 노출 시작
    //       </MDButton>
    //     ) : (
    //       <span />
    //     ),
    // },

    {
      title: "배너 등록 시간",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => dayjs(createdAt).format("YY년 MM월 DD일 HH시 mm분"),
    },

    // 여기 버튼 하나 추가하고 status가 pending이면 '광고 노출 시작' 버튼 추가하고 running이면 빈칸으로 둬
  ];

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      `/pages/banners/request-banner-list${paramsFilter({
        sort,
        order,
        search,
        page,
      })}`
    );
  };

  useEffect(() => {
    Apis.getBannerRequestList({ page: page, perPage: 10, search, sort, order }).then((resolve) => {
      setData(resolve.data.list);
      setTotal(resolve.data.total);
    });
  }, [page, search, sort, order]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <Table
            pagination={false}
            dataSource={data}
            columns={columns}
            rowKey={(record) => record.bannerId}
            style={{ cursor: "pointer" }}
            scroll={{ x: "max-content" }}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  navigate(`/pages/banners/detail-request-banner/${record.bannerId}`);
                },
              };
            }}
            footer={() => (
              <Pagination
                current={page}
                onChange={handleOnChangePage}
                pageSize={10}
                total={total}
                showSizeChanger={false}
              />
            )}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default RequestBannerList;
