import Apis from "apis/remotes";
import { IUserById } from "apis/response";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Card } from "antd";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import UserProfileCard from "./components/ListItem/UserProfileCard";
import { inputToPhone } from "utils/PhoneString";
import dayjs from "dayjs";

function UserDetails(): JSX.Element {
  const { id } = useParams();
  const [user, setUser] = useState<IUserById>();

  useEffect(() => {
    Apis.getUserById(id)
      .then((resolve) => {
        setUser(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Grid container>
          <Grid item sm={12} md={9}>
            <MDBox mt={3} mb={1} ml={3} p={3}>
              <MDBox display="flex" flexDirection={"column"} justifyContent="space-between">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  회원 정보
                </MDTypography>
              </MDBox>
              <Grid container spacing={1}>
                <Grid item sm={12} md={6}>
                  <UserProfileCard
                    info={{
                      유저ID: user?.userId,
                      이메일: user?.email,
                      가입일자: user?.createdAt ? dayjs(user.createdAt).format("YYYY-MM-DD") : "",
                      연락처: inputToPhone(user?.phone ?? ""),
                      유형: user?.type,
                      상태: user?.status,
                      SMS동의: user?.smsConsent ? "동의" : "비동의",
                      포인트: user?.point?.toLocaleString() ?? 0,
                      경고횟수: user?.warningCount?.toLocaleString() ?? 0,
                      총배너일수: user?.totalBannerDay?.toLocaleString() ?? 0,
                      총배너결제금액: user?.totalBannerPaymentAmount
                        ? `${user?.totalBannerPaymentAmount?.toLocaleString()}원`
                        : "0원",
                    }}
                    shadow={false}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}
export default UserDetails;
