import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// ant design
import { Form, Input, message } from "antd";

// NewProduct page components

import Apis from "apis/remotes";

import { useForm } from "antd/es/form/Form";
import { Link, useNavigate } from "react-router-dom";
import FormItem from "antd/es/form/FormItem";

function NewGift(): JSX.Element {
  const [form] = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCreateNotice = async (noticeSubmitForm: FormData) => {
    setLoading(false);
    Apis.postGift(noticeSubmitForm)
      .then((resolve) => {
        message.success("기프티쇼 상품이 등록되었습니다.");
        navigate("/pages/gift/gift-list");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <Card>
            <MDBox p={3}>
              <Form
                labelCol={{ sm: { span: 4 }, span: 24 }}
                wrapperCol={{ sm: { span: 20 }, span: 24 }}
                form={form}
                onFinish={handleCreateNotice}
                autoComplete="off"
              >
                <FormItem
                  label="상품코드"
                  name="goodsCode"
                  rules={[{ required: true, message: "기프티쇼 상품코드를 입력하세요" }]}
                >
                  <Input maxLength={50} showCount />
                </FormItem>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MDButton
                      LinkComponent={Link}
                      to={"/pages/gift/gift-list"}
                      fullWidth
                      variant="contained"
                      color="light"
                    >
                      취소
                    </MDButton>
                  </Grid>
                  <Grid item xs={6}>
                    <MDButton
                      disabled={loading}
                      fullWidth
                      variant="gradient"
                      color="info"
                      type="submit"
                    >
                      등록
                    </MDButton>
                  </Grid>
                </Grid>
              </Form>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default NewGift;
